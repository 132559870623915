import React from 'react';

import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';

import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import { SmallCard, Quote } from '~/src/components/forSolutions';
import { Section } from '../../forMain/Section';
import {
  DeliverScreenDescription,
  DeliverScreenCardsContainer,
} from './styles/DeliverScreen.styles';

export const DeliverScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <SolutionsSubHeading>eLearning Content Delivery Platform</SolutionsSubHeading>
      <DeliverScreenDescription>
        Educate everyone everywhere, with complete file management and media delivery that fits the
        needs of modern education platforms.
      </DeliverScreenDescription>
      <DeliverScreenCardsContainer>
        <SmallCard
          title="Upload lessons and homework"
          src="https://ucarecdn.com/0e928b99-e406-4f5e-9be4-edb80fe78ee5/upload.svg"
        />
        <SmallCard
          title="Secure files and student access"
          src="https://ucarecdn.com/8f9fcd00-0021-4e1f-ae4e-50c6b2a10aef/secure.svg"
        />
        <SmallCard
          title="Deliver courses and eLearning images"
          src="https://ucarecdn.com/b62b40c2-f8ba-4e10-a6cf-2e7771315bc7/deliver.svg"
        />
        <SmallCard
          title="Store, convert, optimize hours of video lessons"
          src="https://ucarecdn.com/862c7c49-b84e-4e6a-8f53-b4b84341f2cd/store.svg"
        />
      </DeliverScreenCardsContainer>
    </Container>
    <Quote
      logoSrc={logos[BuzzLogoIdEnum.PANDADOC_BW].src}
      logoAlt="Pandadoc logo"
      quoteText="Uploadcare is a perfect example of a laser-focused application which
  solves a real problem. And it worked and works perfectly for us."
      avatarSrc="https://ucarecdn.com/df57d446-61f4-44e2-aa9c-cdea4b81a6f0/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Serge Barysiuk | Co-Founder and CTO"
    />
  </Section>
);
