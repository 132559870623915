import React from 'react';

import { Container } from '@uc-common/container';

import { PlanCardFree } from '../../PlanCardFree';
import { FooterHeading, FooterDescription, FooterSection } from './styles/Footer.styles';

export const Footer = () => (
  <FooterSection data-navbar-theme="dark">
    <Container>
      <FooterHeading>Educate the world with Uploadcare.</FooterHeading>
      <FooterDescription>
        Educate everywhere, with complete file management and media delivery that fits the needs of
        modern e-learning platforms.
      </FooterDescription>
      <PlanCardFree btnSignUpProps={{ 'data-analytics': 'btn_signup_2' }} />
    </Container>
  </FooterSection>
);
