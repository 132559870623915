import styled, { css } from 'styled-components';
import { Container as Wrapper } from '@uc-common/container';
import { CTAButton } from '../../../forMain/Typography';

export const MediaPiplineScreenSection = styled(Wrapper)`
  padding-top: 100px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MediaPiplineScreenCardsContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-top: 40px;

    ${tablet()} {
      grid-template-columns: repeat(2, 1fr);
    }

    ${laptop()} {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 13px;
    }
  `
);

export const MediaPiplineScreenButton = styled(CTAButton)`
  margin-top: 30px;
  max-width: 300px;
  width: 100%;
  font-size: 16px;
`;
