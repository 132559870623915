import styled, { css } from 'styled-components';
import { Section } from '../../../forMain/Section';

export const IndustryResourcesScreenHeading = styled.h2(
  ({ theme: { tablet, laptop } }) => css`
    text-align: center;
    color: #ffffff;
    max-width: 910px;
    margin: 0 auto;
    font-size: 28px;
    line-height: 1.4;
    letter-spacing: -0.02em;

    ${tablet()} {
      font-size: 38px;
    }

    ${laptop()} {
      font-size: 42px;
    }
  `
);

export const IndustryResourcesScreenResourcesGrid = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 45px;
    margin-top: 30px;

    ${tablet()} {
      grid-gap: 60px 8px;
      grid-template-columns: 1fr 1fr;
      margin-top: 40px;
    }

    ${laptop()} {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 50px;
    }
  `
);

export const IndustryResourcesScreenSection = styled(Section)(
  ({ theme: { tablet, laptop } }) => css`
    padding-bottom: 120px;

    ${tablet()} {
      padding-bottom: 140px;
    }

    ${laptop()} {
      padding-bottom: 170px;
    }
  `
);
