import React from 'react';

import { ButtonTypeEnum } from '@uc-common/button';

import { Clients } from '~/src/components/Clients';
import { Link } from '../../Link';
import { GradientSuperHeading } from '../../Typography';
import {
  Heading,
  StyledContainer,
  StyledSection,
  StyledSubText,
  SectionHeaderButton,
} from './styles/SectionHeader.styles';

export const SectionHeader = ({ btnSignUpProps = {} }) => (
  <StyledSection>
    <StyledContainer>
      <GradientSuperHeading>Online learning platforms</GradientSuperHeading>
      <Heading>CDN and File Management For eLearning Platforms</Heading>

      <StyledSubText>
        Meet Uploadcare: the cloud content platform to handle all your eLearning materials — from
        uploading presentations to&nbsp;delivering course media to&nbsp;students planet-wide.
      </StyledSubText>

      <SectionHeaderButton
        forwardedAs={Link}
        to="/accounts/signup/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_signup_0"
        {...btnSignUpProps}
      >
        Get started for free
      </SectionHeaderButton>

      <Clients />
    </StyledContainer>
  </StyledSection>
);
