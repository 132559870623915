import React from 'react';

import { SiteLayout } from '../../components/Layout/SiteLayout';
import {
  SectionHeader,
  DeliverScreen,
  ServicesScreen,
  MediaPiplineScreen,
  FeaturesScreen,
  Footer,
} from '../../components/forELearning';

export const frontmatter = {
  title: 'ELearning education platforms',
  description:
    'Meet Uploadcare: the cloud content platform to build your entire eLearning media pipeline upon — from uploading presentations to delivering course media to students planet-wide.',
};

const ELearning = ({ location }) => (
  <SiteLayout meta={{ ...frontmatter }} pathName={location.pathname} isDark>
    <SectionHeader
      btnSignUpProps={{
        'data-analytics': 'btn_signup_0',
      }}
    />
    <DeliverScreen />
    <ServicesScreen />
    <MediaPiplineScreen />
    <FeaturesScreen />
    <Footer />
  </SiteLayout>
);

export default ELearning;
