export const data = [
  {
    title: 'Responsive course media',
    text: 'Optimize course media for all devices, save on hosting and improve student experience.',
  },
  {
    title: 'Globally scalable CDN',
    text: 'Multi-CDN scales for any volume of students, exam sessions, or video streaming.',
  },
  {
    title: 'Secure storage',
    text: 'Keep your files safe in cloud storage, with automated backups and detailed access policies.',
  },
  {
    title: 'Easy LMS integration',
    text: 'Manage content from Uploadcare dashboard or integrate with LMS/CRM via API.',
  },
  {
    title: 'Detailed user profiles',
    text: 'Manage multiple account types for students and educators. Simplify support and access to content.',
  },
  {
    title: 'AI content recognition',
    text: 'Analyze assignments and media at scale with AI recognition of objects, face, and other content.',
  },
];
