import React from 'react';

import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';

import { Section } from '~/src/components/forMain/Section';
import { FeatureCard } from '~/src/components/forSolutions';

import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import {
  FeaturesScreenGradientSuperHeading,
  FeaturesScreenFeaturesGrid,
  FeaturesScreenIntegrationSection,
  FeaturesScreenIntegrationContainer,
  FeaturesScreenIntegrationTitle,
  FeaturesScreenIntegrationParagraph,
  FeaturesScreenIntegrationLogo,
  FeaturesScreenArrowIcon,
  FeaturesScreenArrowButton,
  FeaturesScreenArrowButtonText,
} from './styles/FeaturesScreen.styles';
import { data } from './data';

export const FeaturesScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <FeaturesScreenGradientSuperHeading>
        eLearning product features
      </FeaturesScreenGradientSuperHeading>
      <SolutionsSubHeading>
        Future-proof your online education with modern content delivery
      </SolutionsSubHeading>

      <FeaturesScreenFeaturesGrid>
        {data.map(({ title, text }) => (
          <FeatureCard key={title} title={title} text={text} />
        ))}
      </FeaturesScreenFeaturesGrid>
    </Container>

    <FeaturesScreenIntegrationSection>
      <FeaturesScreenIntegrationContainer>
        <FeaturesScreenIntegrationLogo
          src={logos[BuzzLogoIdEnum.SUPERVISION_ASSIST].src}
          alt="SUPERVISION_ASSIST-logo"
        />
        <FeaturesScreenIntegrationTitle>
          Saving 50 hours year while staying HIPAA-compliant
        </FeaturesScreenIntegrationTitle>
        <FeaturesScreenIntegrationParagraph>
          SupervisionAssist helps university coordinators manage students’ training programs in
          behavioral health. With Uploadcare, they built a secure and HIPAA-compliant solution to
          handle sensitive and large training video files.
        </FeaturesScreenIntegrationParagraph>
        <FeaturesScreenArrowButton
          to="/customers/supervisionassist/"
          data-analytics="btn_supervision-assist"
        >
          <FeaturesScreenArrowButtonText>Read more</FeaturesScreenArrowButtonText>
          <FeaturesScreenArrowIcon />
        </FeaturesScreenArrowButton>
      </FeaturesScreenIntegrationContainer>
    </FeaturesScreenIntegrationSection>
  </Section>
);
