import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { SubText, CTAButton } from '../../../forMain/Typography';
import { Section } from '../../../forMain/Section';
import diamondGradient from '../../assets/diamondGradient.svg';

export const Heading = styled.h1(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 19px;
    color: #ffffff;
    display: inline-flex;
    flex-direction: column;

    ${tablet()} {
      font-size: 56px;
      margin-bottom: 30px;
    }

    ${laptop()} {
      font-size: 60px;
    }

    ${desktop()} {
      font-size: 70px;
      max-width: 910px;
    }
  `
);

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const StyledSection = styled(Section)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    padding-top: 90px;
    background: url(${diamondGradient}) no-repeat center;
    background-size: 800px auto;

    ${tablet()} {
      padding-top: 173px;
      background-position-y: 10px;
      background-size: auto auto;
    }

    ${laptop()} {
      padding-top: 160px;
      background-position-y: 15px;
    }

    ${desktop()} {
      padding-top: 190px;
      background-position-y: 0;
      background-size: 1200px auto;
    }
  `
);

export const StyledSubText = styled(SubText)(
  ({ theme: { laptop } }) => css`
    max-width: 640px;

    ${laptop()} {
      max-width: 830px;
    }
  `
);

export const SectionHeaderButton = styled(CTAButton)`
  margin-top: 30px;
  max-width: 300px;
  width: 100%;
  font-size: 16px;
`;
