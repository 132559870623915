export const SERVICES = [
  {
    superHeading: 'File Uploader',
    heading: 'Allow anyone to upload files securely',
    paragraph:
      'Our leading file uploader ensures all files reach your platform — from enrollment forms, to hours of video lessons, to assignments due 23:59! Build better courses and simplify the learning experience.',
    options: [
      'Responsive HTML5 uploader',
      'Customizable look & feel',
      'Any file type and compression',
      'Up to 5TB per file',
    ],
    imageUuid: 'd34aa0e4-0a40-476c-8868-82ffbb43efb2',
    imageAlt: 'Widget image editor',
  },
  {
    superHeading: 'eLearning file storage',
    heading: 'Organize and secure educational content',
    paragraph:
      'Store course assets and student data on our global cloud storage, secured with the latest security and compliance measures. Manage files right from Uploadcare, or integrate easily with your LMS and admin panel of choice.',
    options: [
      'Fast storage and backups',
      'User-level access permissions',
      'Virus & malware scanning',
      'Secure encryption',
    ],
    imageUuid: '06933f0d-5f31-425d-a5b8-6c5c2cdaaae6',
    imageOptions: { preview: '777x532', quality: 'smart', format: 'auto' },
    imageAlt: 'Widget image editor',
  },
  {
    superHeading: 'CDN & content delivery',
    heading: 'Create Responsive eLearning for any device',
    paragraph:
      'Uploadcare’s smart CDN optimizes image resolution and file size for each student device. Presentations and media load fast and sharp, saving you up to 80% on hosting expenses.',
    options: [
      '4 CDNs in 135+ countries',
      'Resistant to traffic surges',
      '99.99% guaranteed uptime',
      'Auto-responsive media',
    ],
    imageUuid: '5f3615b1-3a9b-4ad5-a5d9-3439bbfbd1de',
    imageOptions: { preview: '798x532', quality: 'smart', format: 'auto' },
    imageAlt: 'Widget image editor',
  },
];
