import styled, { css } from 'styled-components';
import Image from '@uc-common/image';
import { Link } from '../../../../../Link';

export const ResourceCardContainer = styled(Link)`
  background-color: #000000;
  border: 1px solid #21262f;
  border-radius: 12px;
  padding: 30px 25px 35px 25px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  transition: background-color 0.3s ease-in;

  &:hover {
    background-color: #08080a;
  }
`;

export const ResourceCardImage = styled(Image)`
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
  object-fit: cover;
`;

export const ResourceCardHeading = styled.h4`
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 12px;
  margin-top: 15px;
`;

export const ResourceCardText = styled.p(
  ({ theme: { desktop } }) => css`
    font-size: 15px;
    line-height: 160%;
    color: #bbc4d2;

    ${desktop()} {
      font-size: 16px;
    }
  `
);
