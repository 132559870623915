import React from 'react';

import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';
import { ButtonTypeEnum } from '@uc-common/button';
import { Quote } from '~/src/components/forSolutions';

import {
  SolutionsSubHeading,
  SolutionsDescription,
} from '~/src/components/forSolutions/styled-components';
import { Section } from '../../forMain/Section';
import { Link } from '../../Link';
import { MediaPiplineScreenButton } from './styles/MediaPiplineScreen.styles';

export const MediaPiplineScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <SolutionsSubHeading>
        Create your eLearning platform with Uploadcare’s leading media pipeline
      </SolutionsSubHeading>
      <SolutionsDescription>
        Build your e-learning platform on top of a complete media pipeline that plays well with your
        stack and workflow. Spend more time educating rather than maintaining servers: for all
        things files, we’ve got you covered, and secure.
      </SolutionsDescription>

      <MediaPiplineScreenButton
        forwardedAs={Link}
        to="/accounts/signup/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_signup_1"
      >
        Get started for free
      </MediaPiplineScreenButton>
    </Container>

    <Quote
      logoSrc={logos[BuzzLogoIdEnum.MOZILLA].src}
      logoAlt="Mozilla logo"
      quoteText="It's the most painless, enjoyable flow out there. Awesome product. We
      all love Uploadcare dearly."
      avatarSrc="https://ucarecdn.com/6bf9903e-584d-459c-b621-819a5bf4910a/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Chris Van Wiemeersch | UX Engineer"
    />
  </Section>
);
