import styled, { css } from 'styled-components';
import { GradientSuperHeading } from '~/src/components/Typography';
import { SectionParagraph } from '~/src/components/forFileUploader/styles';
import { Logo } from '~/src/components/forMain/LastScreen/Review';
import { ReactComponent as ArrowIcon } from '~/src/components/forMain/ReviewCard/assets/arrow.svg';
import { Link } from '~/src/components/Link';

export const FeaturesScreenGradientSuperHeading = styled(GradientSuperHeading)`
  align-self: center;
  display: block;

  background: radial-gradient(141.01% 172.5% at 0% 0%, #b951be 0%, #9365fd 84.45%);
`;

export const FeaturesScreenFeaturesGrid = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 20px;
    margin-top: 50px;

    ${tablet()} {
      grid-template-columns: 1fr 1fr;
      margin-top: 70px;
    }

    ${laptop()} {
      grid-template-columns: repeat(3, 1fr);
    }
  `
);

export const FeaturesScreenIntegrationSection = styled.div(
  ({ theme: { laptop } }) => css`
    width: 100%;
    padding: 70px 35px;
    background-color: #08080a;

    margin-top: 90px;

    ${laptop()} {
      margin-top: 130px;
    }
  `
);

export const FeaturesScreenIntegrationContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 790px;
  color: #ffffff;
`;

export const FeaturesScreenIntegrationTitle = styled.h4(
  ({ theme: { tablet, laptop } }) => css`
    color: #ffffff;
    text-align: center;
    font-size: 23px;
    line-height: 1.3;
    margin-top: 30px;

    ${tablet()} {
      font-size: 25px;
    }

    ${laptop()} {
      font-size: 28px;
      text-align: left;
    }
  `
);

export const FeaturesScreenIntegrationParagraph = styled(SectionParagraph)`
  color: #bbc4d2;
`;

export const FeaturesScreenIntegrationLogo = styled(Logo)`
  justify-self: flex-start;
  height: 25px;
  width: auto;
`;

export const FeaturesScreenArrowIcon = styled(ArrowIcon)`
  color: #bbc4d2;
  transition:
    transform 0.2s ease,
    color 0.2s ease-in;
  cursor: pointer;
  width: 23px;
  height: auto;
`;

export const FeaturesScreenArrowButtonText = styled.p(
  ({ theme: { tablet, laptop } }) => css`
    color: #bbc4d2;
    text-align: center;
    font-size: 16px;
    opacity: 0;
    transition:
      visibility 0.1s ease-in,
      opacity 0.2s ease-in,
      color 0.2s ease-in;
    margin-right: 15px;
    visibility: hidden;

    ${tablet()} {
      font-size: 18px;
    }

    ${laptop()} {
      font-size: 20px;
      text-align: left;
    }
  `
);

export const FeaturesScreenArrowButton = styled(Link)`
  display: block;
  display: flex;
  justify-content: right;
  align-items: center;
  text-decoration: none;
  margin-top: 50px;
  height: 32px;

  &:hover {
    ${FeaturesScreenArrowIcon} {
      transform: translateX(0.3em);
      color: #ffffff;
    }

    ${FeaturesScreenArrowButtonText} {
      visibility: visible;
      opacity: 1;
      color: #ffffff;
    }
  }
`;
